<template>
	<div>
		<div class="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-20 lg:px-8">
			<div class="relative mt-10">
				<div class="absolute top-0 right-0">
					<img class="rounded-3xl shadow-xl border-4 border-black" :src="require('@/assets/core/general.png')" />
				</div>

				<div class="relative px-8 xl:px-32 lg:pt-12 xl:pt-16">
					<div class="p-4 md:px-12 lg:px-24 sm:pt-12 sm:pb-10 xl:pb-16 bg-gradient-to-b from-gray-50 to-gray-300 rounded-3xl border-8 border-black">
						<div class="flex justify-center">
								<div>
									<div class="flex justify-center">
										<img :src="require(	'@/assets/game/question/pleca.png')" class="w-80" style="margin-top:-70px">
									</div>
									<div class="flex justify-center" style="margin-top:-56px">
										<img :src="require('@/assets/core/logo-chiesi.png')" class=" w-36">
									</div>
								</div>
							</div>

						<div class="text-center text-xl lg:text-3xl font-semibold mt-10">
							Elige tu medio de navegación
						</div>

						<div class="grid grid-cols-2 gap-4 lg:gap-6 mt-8">
							<div class="col-span-1 flex justify-center items-center p-6 lg:p-12 bg-white cursor-pointer rounded-3xl shadow-xl border-4 border-primary hover:border-accent" @click="play(1)" :class="state.selected==1 ? 'border-accent' : 'border-white'">
								<div>
									<img :src="require('@/assets/game/barcos/barco1.png')" />
								</div>
							</div>
							<div class="col-span-1 flex justify-center items-center p-6 lg:p-12 bg-white cursor-pointer rounded-3xl shadow-xl border-4 border-primary hover:border-accent" @click="play(2)" :class="state.selected==2 ? 'border-accent' : 'border-white'">
								<div>
									<img :src="require('@/assets/game/barcos/barco2.png')" />
								</div>
							</div>
							<div class="col-span-1 flex justify-center items-center p-6 lg:p-12 bg-white cursor-pointer rounded-3xl shadow-xl border-4 border-primary hover:border-accent" @click="play(3)" :class="state.selected==3 ? 'border-accent' : 'border-white'">
								<div>
									<img :src="require('@/assets/game/barcos/barco3.png')" />
								</div>
							</div>
							<div class="col-span-1 flex justify-center items-center p-6 lg:p-12 bg-white cursor-pointer rounded-3xl shadow-xl border-4 border-primary hover:border-accent" @click="play(4)" :class="state.selected==4 ? 'border-accent' : 'border-white'">
								<div>
									<img :src="require('@/assets/game/barcos/barco4.png')" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- <div class="flex justify-center mt-8" v-if="state.selected != 0">
				<button type="button" @click="play" class="inline-flex items-center shadow-lg hover:shadow-xl px-6 py-3 border-4 border-green-500 border-opacity-20 text-base font-medium rounded-md text-white bg-green-400 hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-green-500">
					CONTINUAR
				</button>
			</div>

			<div class="flex justify-center mt-8" v-if="state.selected == 0">
				<div class="inline-flex items-center px-6 py-3 text-base font-medium rounded-md text-blue-gray-50 bg-blue-gray-200">
					CONTINUAR
				</div>
			</div> -->
		</div>
	</div>
</template>

<script>
import { reactive } from 'vue'
export default {
	setup(props, { emit }) {
		const state = reactive({
			selected: 0
		});

		function setFrame(num) {
			state.selected = num;
		}

		function play(num) {
			emit('playNow', num);
		}

		return {
			state,
			setFrame,
			play
		}
	}
}
</script>