<template>
	<TransitionRoot as="template" :show="open">
		<Dialog as="div" static class="fixed z-10 inset-0 overflow-y-auto" :open="open">
			<div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
				<TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
					<DialogOverlay class="fixed inset-0 bg-black bg-opacity-60 transition-opacity" />
				</TransitionChild>

				<span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
				<TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
					<div class="inline-block align-bottom bg-gradient-to-b from-gray-50 to-gray-300 rounded-3xl text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full border-4 sm:border-8 border-black">
						<div class="flex justify-center">
							<div>
								<div class="flex justify-center"><img :src="require('@/assets/game/question/pleca.png')" class="-mt-4 w-3/5 sm:w-2/5"></div>
								<div class="text-center -mt-10 text-lg sm:text-xl font-semibold text-white">Pregunta {{state.actual+1}}</div>
							</div>
						</div>

						<div class="p-4 sm:p-6" v-if="questions.length > 0">
							<div class="text-lg leading-6 text-center font-medium text-gray-900 md:px-10" v-html="questions[state.actual].title"></div>

							<div class="text-center text-sm font-medium text-accent mt-2" v-if="!state.presentFeedback">
								<span v-if="state.opportunity == 0">
									(1er oportunidad valor 1,000 puntos)
								</span>
								<span v-if="state.opportunity == 1">
									(2da oportunidad valor 500 puntos)
								</span>
								<span v-if="state.opportunity == 2">No se aplicaron puntos.</span>
							</div>
							<div class="mt-2" v-if="state.answeredCorrect">
								<div class="font-bold text-green-500 text-center" v-if="state.opportunity == 0">¡EXCELENTE!</div>
								<div class="font-bold text-green-500 text-center" v-if="state.opportunity == 1">¡MUY BIEN!</div>
							</div>

							<div class="text-sm text-left text-gray-600 mt-6" v-if="!state.sending">
								<div class="space-y-6" v-if="!state.presentFeedback">
									<div v-for="(item, index) in questions[state.actual].answers" :key="item.id">
										<div class="flex items-center">
											<div class="flex-none -mr-6 z-20 mt-1">
												<img :src="require('@/assets/game/question/p1.png')" class="w-12" v-if="index==0">
												<img :src="require('@/assets/game/question/p2.png')" class="w-12" v-if="index==1">
												<img :src="require('@/assets/game/question/p3.png')" class="w-12" v-if="index==2">
												<img :src="require('@/assets/game/question/p4.png')" class="w-12" v-if="index==3">
												<img :src="require('@/assets/game/question/p5.png')" class="w-12" v-if="index==4">
											</div>
											<button type="button" class="bg-white shadow-md pl-8 pr-3 py-2 rounded-xl flex-1 outline-none focus:outline-none" :disabled="state.opportunity == 2 || state.answeredCorrect" @click="choosen(index+1)" v-html="item" :class="covered(index+1)"></button>
										</div>
									</div>
								</div>

								<div class="mt-4 text-primary text-xs text-center" v-if="!state.presentFeedback && (state.opportunity == 2 || state.answeredCorrect)">
									<button class="font-bold outline-none focus:outline-none p-4 bg-transparent" @click="state.presentFeedback=true">VER RETROALIMENTACIÓN</button>
								</div>

								<div class="text-base text-left text-gray-600 mt-4 px-2" v-if="state.presentFeedback">
									<div v-if="questions[state.actual].feedback.length > 0">
										<div class="text-sm font-bold text-primary">COMENTARIOS</div>
										<div class="mt-1" v-html="questions[state.actual].feedback"></div>
									</div>

									<div v-if="questions[state.actual].reference.length > 0">
										<div class="mt-4 text-sm font-bold text-primary">REFERENCIA</div>
										<div class="mt-1" v-html="questions[state.actual].reference"></div>
									</div>
								</div>
							</div>
						</div>

						<div class="px-4 -pt-4 pb-4 sm:px-6 sm:flex sm:flex-row-reverse" v-if="state.opportunity == 2 || state.answeredCorrect">
							<button type="button" class="uppercase text-xs w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-accent font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto tracking-wide" v-if="state.actual < 4" @click="nextOne">
								Siguiente pregunta
							</button>

							<button type="button" class="uppercase text-xs w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-black font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto tracking-wide" v-if="state.actual == 4" @click="nextPhase">
								Terminar fase
							</button>
						</div>
					</div>
				</TransitionChild>
			</div>
		</Dialog>
	</TransitionRoot>
</template>

<script>
import { ref, onMounted, reactive } from 'vue'
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { ExclamationIcon } from '@heroicons/vue/outline'
import Questions from '@/firebase/questions'

export default {
	components: {
		Dialog,
		DialogOverlay,
		DialogTitle,
		TransitionChild,
		TransitionRoot,
		ExclamationIcon,
	},
	props: ['open', 'questions', 'user'],
	emits: ['finished', 'saving', 'showing'],
	setup(props, {emit}) {

		const state = reactive({
			actual: 0,
			opportunity: 0,
			presentFeedback: false,
			answeredCorrect: false,
			blockers: [],
			numResponded: null,
			responded: false,
			initTimer: null,
			endTimer: null,
		})

		onMounted(() => {
			state.initTimer = Date.now()
		})

		const covered = (num) => {
			if (state.responded) {
				// CORRECTA
				if (state.answeredCorrect === true) {
					if (state.numResponded == num) {
						return 'bg-green-500 text-white cursor-not-allowed';	
					} else {
						return 'opacity-40 cursor-not-allowed';
					}
				}
				
				// INCORRECTA
				if (state.answeredCorrect === false) {
					if (state.numResponded == num) {
						return 'bg-red-500 text-white cursor-not-allowed';
					} 
					
					if (state.opportunity == 2) {
						return 'bg-white text-gray-900 opacity-40 cursor-not-allowed';
					}
				}
			}
			
			return 'bg-white hover:bg-blue-500 hover:text-blue-50 cursor-pointer'
		}

		const setBlock = (num) => {
			state.question.answers.forEach( (dt, index) => {
				if (index != (num-1)) {
					state.blockers.push(index)
				}
			})
		}

		const choosen = (num) => {
			evaluate(num)
			
			if (state.answeredCorrect == false) {
				state.opportunity++

				if (state.opportunity == 2) {
					saveAnswer()
				}
			} else {
				saveAnswer()
			}

			state.numResponded = num
			state.responded = true
		}

		const evaluate = (num) => {
			if (props.questions[state.actual].correct == num) {
				state.answeredCorrect = true
			} else {
				state.answeredCorrect = false
			}
		}

		const saveAnswer = async () => {
			state.endTimer = Date.now()
			const timer = state.endTimer - state.initTimer

			let points = 0
			if (state.opportunity == 0)  points = 1000
			if (state.opportunity == 1)  points = 500

			const finalAns = JSON.parse(JSON.stringify(props.user.answers))
			
			finalAns[props.questions[state.actual].id] = {
				responded: true,
				points: points
			}

			emit('saving', points)
			const resultant = await Questions.saveAnswer(props.user.uid, finalAns, points, timer)
		}

		const nextOne = () => {
			state.actual++
			state.opportunity = 0
			state.presentFeedback = false
			state.answeredCorrect = false
			state.blockers = []
			state.numResponded = null
			state.responded = false
			emit('showing')
			state.initTimer = Date.now()
		}

		const nextPhase = () => {
			emit('finished')
		}

		return {
			state,
			covered,
			choosen,
			nextOne,
			nextPhase
		}
	}
}
</script>