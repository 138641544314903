<template>
	<div class="mt-0" v-if="!state.lock">
		<!-- POINTS AND TIMER -->
		<div class="max-w-4xl lg:max-w-3xl xl:max-w-4xl mx-auto text-xl sm:text-3xl font-semibold px-4 lg:px-10 xl:px-4 flex items-center justify-between" :class="state.started ? 'text-white' : 'text-black'" v-if="!state.levelTerminated">
			<div class="space-x-1">
				<span>{{ state.points }}</span>
				<span class="text-lg sm:text-xl lg:text-2xl">Pts.</span>
			</div>
			<div class="text-right space-x-1">
				<span>{{ formatTimer.minutes }}</span>
				<span>:</span>
				<span>{{ formatTimer.seconds }}</span>
			</div>
		</div>

		<!-- MAPPER -->
		<div v-if="!state.levelTerminated">
			<Mapper 
				:user="state.user" 
				:initiated="state.initiated" 
				:showQuestion="state.showQuestion" 
				@go="initTime" @arrived="arrived" />
		</div>

		<!-- FARO -->
		<div class="max-w-4xl mx-auto" v-else>
			<div class="mt-2 relative">
				<img class="absolute rounded-lg" :src="require('@/assets/game/faro.jpg')" alt="">
				<div class="inline-block ml-6 sm:ml-16 align-bottom bg-gradient-to-b from-gray-50 to-gray-300 rounded-3xl text-left shadow-xl transform transition-all my-4 sm:my-8 sm:align-middle w-2/3 sm:max-w-lg sm:w-full border-4 sm:border-8 border-black">
					<div class="flex justify-center">
						<div>
							<div class="flex justify-center"><img :src="require('@/assets/game/question/pleca.png')" class="-mt-2 sm:-mt-4 w-3/5 sm:w-2/5"></div>
							<div class="text-center -mt-8 sm:-mt-10 text-base sm:text-xl font-semibold text-white">¡Felicidades!</div>
						</div>
					</div>

					<div class="font-semibold text-center mt-5 sm:mt-8 text-xs sm:text-lg md:text-xl xl:text-2xl px-2 sm:px-6">
						¡Enhorabuena, has completado el recorrido satisfactoriamente!
					</div>

					<div class="mb-2 sm:mb-8">
						<div class="font-bold text-center mt-2 sm:mt-4 text-xs sm:text-lg text-accent">TU PUNTUACIÓN</div>
						<div class="font-bold text-center mt-0 text-lg sm:text-3xl text-primary">{{ state.points }} pts.</div>
					</div>

					<div class="-mb-4 sm:-mb-6 flex justify-center">
						<button class="py-1 sm:py-2 px-6 sm:px-8 bg-accent hover:bg-black transform duration-300 text-white font-medium text-sm sm:text-base rounded-md ring-accent focus:outline-none" @click="restart">
							Continuar
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="mt-0 sm:mt-8" v-if="state.lock">
		<div class="max-w-4xl mx-auto">
			<div class="mt-2 relative">
				<img class="absolute rounded-lg" :src="require('@/assets/game/faro.jpg')" alt="">
				<div class="inline-block ml-6 sm:ml-16 align-bottom bg-gradient-to-b from-gray-50 to-gray-300 rounded-3xl text-left shadow-xl transform transition-all my-4 sm:my-8 sm:align-middle w-2/3 sm:max-w-lg sm:w-full border-4 sm:border-8 border-black">
					<div class="flex justify-center">
						<div>
							<div class="flex justify-center"><img :src="require('@/assets/game/question/pleca.png')" class="-mt-2 sm:-mt-4 w-3/5 sm:w-2/5"></div>
							<div class="text-center -mt-6 sm:-mt-9 text-xs sm:text-base font-semibold text-white">Nivel completado</div>
						</div>
					</div>

					<div class="font-medium text-center mt-6 sm:mt-8 text-sm xl:text-base px-2 sm:px-6 pb-4 sm:pb-8 leading-tight">
						Muy pronto se desbloqueará una nueva etapa y con ella la oportunidad de elevar tu puntuación.
					</div>
				</div>
			</div>
		</div>
	</div>

	<!-- QUESTION -->
	<DiagQuestion 
		:open="state.showQuestion" 
		:questions="state.questions"
		:user="state.user"
		@finished="phaseFinished"
		@saving="isSaving"
		@showing="globalTimer(true)"
		v-if="!state.loading" />

	<!-- FOOTER -->
	<FooterIntern :class="!state.levelTerminated ? 'mt-16' : 'mt-10 lg:mt-32'" />
</template>

<script>
import { reactive, computed, watch, onMounted } from 'vue';
import Game from '@/components/game/Game'
import FooterIntern from '@/components/core/FooterIntern'
import SelectLancha from '@/components/game/SelectLancha'
import Question from '@/components/game/Question'
import DiagQuestion from '@/components/game/DialogQuestion'
import { Howl, Howler } from 'howler'
import { useStore } from 'vuex'
import NotAnyMore from '@/components/game/NotAnyMore'
import PlayAgain from '@/components/game/PlayAgain'
import Mapper from '@/components/game/Mapper'
import Questions from '@/firebase/questions'

const activeLevels = 6
const questionsPerLevel = 15

export default {
	components: {
		Game,
		FooterIntern,
		SelectLancha,
		Question,
		NotAnyMore,
		PlayAgain,
		Mapper,
		DiagQuestion
	},
	setup() {
		const store = useStore();

		const user = computed(() => store.getters.user);

		const state = reactive({
			loading: false,
			points: 0,
			timer: {
				minutes: 0,
				seconds: 0
			},
			showQuestion: false,
			started: false,
			user: user,
			answers: [],
			userLevel: 0,
			listToQuestion: [],
			questions: [],
			initiated: false,
			levelTerminated: false,
			tempo: null,
			lock: false
		})

		onMounted(() => {
			setAnswersList()
			setUserLevel()

			if (state.userLevel > activeLevels) {
				state.lock = true
			}
			state.answers = []
		})

		const restart = () => {
			setAnswersList()
			setUserLevel()

			if (state.userLevel > activeLevels) {
				state.lock = true
			} else {
				state.points = 0
				state.timer.minutes = 0
				state.timer.seconds = 0
				state.started = false
				state.answers = []
				state.listToQuestion = []
				state.questions = []
				state.initiated = false
				state.levelTerminated = false
				state.tempo = null
				state.lock = false
				initGame()
			}
		}

		const initGame = () => {
			setAnswersList()
			setUserLevel()

			if (state.userLevel <= activeLevels) {
				saveFiverInit()
			} else {
				state.lock = true
			}
		}

		const setAnswersList = () => {
			if (state.user.answers != null) {
				for (let key in state.user.answers) {
					state.answers.push({
						id: Number(key),
						...state.user.answers[key]
					})
				}
			}
		}

		const setUserLevel = () => {
			if (state.answers.length < 15) {
				state.userLevel = 1
			} else if (state.answers.length < 30) {
				state.userLevel = 2
			} else if (state.answers.length < 45) {
				state.userLevel = 3
			} else if (state.answers.length < 60) {
				state.userLevel = 4
			} else if (state.answers.length < 75) {
				state.userLevel = 5
			} else if (state.answers.length < 90) {
				state.userLevel = 6
			}
		}

		const saveFiverInit = async (est) => {
			state.loading = true
			state.listToQuestion = []
			let indice = state.answers.length
			const limit = indice + 5

			for (let i=indice; i<limit; i++) {
				const diff = (state.userLevel-1) * 15
				const index = i - diff

				state.answers.push(state.user.questionOrder[index] + diff)
				state.listToQuestion.push(state.user.questionOrder[index] + diff)
			}

			let answers = JSON.parse(JSON.stringify(state.user.answers))

			if (answers == null) {
				answers = {}
			}

			state.listToQuestion.forEach(dt => {
				answers[dt] = {
					points: 0,
					responded: false
				}
			})

			const p1 = await Questions.get(String(state.userLevel), String(state.listToQuestion[0]))
			state.questions[0] = {...p1.doc.data(), id: String(state.listToQuestion[0])}

			const p2 = await Questions.get(String(state.userLevel), String(state.listToQuestion[1]))
			state.questions[1] = {...p2.doc.data(), id: String(state.listToQuestion[1])}

			const p3 = await Questions.get(String(state.userLevel), String(state.listToQuestion[2]))
			state.questions[2] = {...p3.doc.data(), id: String(state.listToQuestion[2])}

			const p4 = await Questions.get(String(state.userLevel), String(state.listToQuestion[3]))
			state.questions[3] = {...p4.doc.data(), id: String(state.listToQuestion[3])}

			const p5 = await Questions.get(String(state.userLevel), String(state.listToQuestion[4]))
			state.questions[4] = {...p5.doc.data(), id: String(state.listToQuestion[4])}

			const resultant = await Questions.presetIt(user.value.uid, answers)
			if (resultant === true) {
				state.loading = false
			}
		}

		const initTime = (evt) => {
			if (evt < 5) {
				globalTimer(true)
				start()
			}
		}

		const start = () => {
			initGame()
			state.started = true
			state.initiated = true
		}

		const globalTimer = (est) => {
			if (est) {
				state.tempo = setInterval(() => {
					if (state.timer.seconds < 59) {
						state.timer.seconds += 1
					} else {
						state.timer.minutes++
						state.timer.seconds = 0
					}
				}, 1000);
			} else {
				clearInterval(state.tempo)
			}
		}

		const formatTimer = computed(() => {
			let tempo = {
				minutes: '',
				seconds: ''
			}

			if (state.timer.seconds < 10) {
				tempo.seconds = '0' + state.timer.seconds;
			} else {
				tempo.seconds = state.timer.seconds;
			}

			if (state.timer.minutes < 10) {
				tempo.minutes = '0' + state.timer.minutes;
			} else {
				tempo.minutes = state.timer.minutes;
			}

            return tempo;
        })

		const formattedPoints = computed(() => {
			if (state.points < 10) {
				return '0000' + state.points;
			}
			if (state.points >= 10 && state.points < 100) {
				return '000' + state.points;
			}
			if (state.points >= 100 && state.points < 1000) {
				return '00' + state.points;
			}
			if (state.points >= 1000) {
				return '0' + state.points;
			}
			return '00000';
		})

		const arrived = async (evt) => {
			if (evt < 4) {
				state.showQuestion = true
			} else {
				state.levelTerminated = true
			}
		}

		const phaseFinished = () => {
			state.answers = []
			state.started = false
			state.showQuestion = false
			globalTimer(false)
		}

		const isSaving = (evt) => {
			state.points += evt
			globalTimer(false)
		}

		return {
			state,
			start,
			formatTimer,
			formattedPoints,
			arrived,
			phaseFinished,
			globalTimer,
			initTime,
			isSaving,
			restart
		}
	}
}
</script>
