<template>
    <div class="flex justify-center mt-28">
        <div class="max-w-lg">
            

            <div class="text-white text-center mt-10 font-semibold text-lg">
                ¡Enhorabuena, has completado el recorrido satisfactoriamente!
            </div>

            <div class="text-white text-center mt-8 space-x-2">
                <div class="text-sm">Puntos acumulados del juego:</div>
                <div class="text-3xl text-primary font-bold mt-1">{{state.points}}</div>
            </div>

            <div class="mt-6">
                <img class="rounded-md border-2 border-primary border-opacity-60" :src="require('@/assets/points/lighthouse.jpg')" alt="">
            </div>

            <div class="text-white text-center mt-6" v-if="numAnswers == (level*15) || level == 3">
                Por el momento has contestado todas las preguntas, muy pronto se desbloqueará una nueva etapa y con ella la oportunidad de elevar tu puntuación.
            </div>

            <div class="text-white text-center mt-6" v-if="numAnswers < (level*15) && level < 3">
                <div>
                    ¡Felicidades!, ya puedes continuar con el siguiente nivel.
                </div>

                <div class="flex justify-center mt-8">
                    <button class="py-2 px-8 bg-primary text-white font-medium text-lg rounded-md" @click="play">Jugar</button>
                </div>
            </div>
            
            <div class="mt-6 font-semibold text-xl text-primary text-center" v-if="numAnswers == (level*15) || level == 3">
                ¡Espérala!
            </div>
        </div>
    </div>
</template>

<script>
import { reactive } from 'vue'
import Misc from '@/services/misc'

export default {
    props: ['gamePoints', 'level', 'activeLevels', 'numAnswers'],
    emits: ['play'],
    setup(props, {emit}) {
        const state = reactive({
            points: Misc.numberWithCommas(props.gamePoints)
        });

        function play() {
            emit('play')
        }

        return {
            state,
            play
        }
    }
    
}
</script>