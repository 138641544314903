<template>

    <div :class="{'hidden': state.retarding}">
        <div class="relative short-rec sm:hidden mx-auto">
            <img class="absolute rounded-lg" :src="require('@/assets/game/mapa.png')" alt="">
            <img class="absolute w-12" ref="ship1A" :src="require('@/assets/game/lancha.png')" :class="{'invisible': state.flipped}">
            <img class="absolute w-12" ref="ship1B" :src="require('@/assets/game/lanchaFlip.png')" :class="{'invisible': !state.flipped}">
        </div>

        <div class="relative large-rec hidden sm:block xl:hidden mx-auto">
            <img class="absolute rounded-lg" :src="require('@/assets/game/mapa.png')" alt="">
            <img class="absolute w-20" ref="ship2A" :src="require('@/assets/game/lancha.png')" :class="{'invisible': state.flipped}">
            <img class="absolute w-20" ref="ship2B" :src="require('@/assets/game/lanchaFlip.png')" :class="{'invisible': !state.flipped}">
        </div>

        <div class="relative supreme-rec hidden xl:block mx-auto">
            <img class="absolute rounded-lg" :src="require('@/assets/game/mapa.png')" alt="">
            <img class="absolute w-20" ref="ship3A" :src="require('@/assets/game/lancha.png')" :class="{'invisible': state.flipped}">
            <img class="absolute w-20" ref="ship3B" :src="require('@/assets/game/lanchaFlip.png')" :class="{'invisible': !state.flipped}">
        </div>

        <div class="max-w-2xl mx-auto">
            <img class="invisible short-rec sm:hidden mx-auto" :src="require('@/assets/game/mapa.png')" alt="">
            <img class="invisible large-rec hidden sm:block xl:hidden mx-auto" :src="require('@/assets/game/mapa.png')" alt="">
            <img class="invisible supreme-rec hidden xl:block mx-auto" :src="require('@/assets/game/mapa.png')" alt="">

            <div class="mt-6 sm:mt-8 px-2 sm:px-20 lg:px-32" v-if="!state.started">
                <p class="font-medium text-white text-center text-sm sm:text-base leading-snug opacity-80" v-if="initiated == false">
                    Hemos preparado un divertido juego. <br> El objetivo es responder correctamente la mayor cantidad de preguntas hasta llegar a la meta.
                </p>

                <div class="flex justify-center mt-4 sm:mt-6" v-if="state.nextPhase < 4 && !showQuestion">
                    <button class="py-2 px-8 bg-green-500 transform duration-300 hover:bg-green-600 text-white font-medium text-sm sm:text-base rounded-md ring-green-500 focus:outline-none" @click="go">
                        <span v-if="initiated == false">JUGAR</span>
                        <span v-else>JUGAR SIGUIENTE FASE</span>
                    </button>
                </div>
            </div>

            <div class="py-16" v-if="state.started"></div>
        </div>
    </div> 


    <div v-if="state.retarding" class="py-20">
        <div class="flex justify-center">
            <Loader />
        </div>
        <div class="mt-6 text-white font-semibold text-xs text-center">
            CARGANDO JUEGO
        </div>
    </div>
</template>

<script>
import gsap from 'gsap'
import { onMounted, ref, reactive, watch } from 'vue'
import Points from '@/services/points'
import Loader from '@/components/core/Loader'

export default {
    components: {
        Loader
    },
    props: ['user', 'initiated', 'levelFinished', 'showQuestion'],
    emits: ['go', 'arrived'],
    setup(props, {emit}) {
        const animDur = 1.6
        const animEase = 'easeInOut'

        const ship1A = ref(null)   
        const ship1B = ref(null) 

        const ship2A = ref(null)
        const ship2B = ref(null)

        const ship3A = ref(null)
        const ship3B = ref(null)

        onMounted(() => {
            setTimeout(() => {
                state.retarding = false
            }, 3000)

            setPosInit()
        }) 

        const state = reactive({
            points: Points.data,
            nextPhase: 0,
            started: false,
            flipped: false,
            retarding: true
        })

        watch(() => props.showQuestion, () => {
            if (state.nextPhase == 4 && props.showQuestion == false) {
                go()
            }
        })

        const setPosInit = () => {
            if (props.user.answers == null) {
                state.nextPhase = 1
                setInitials(0)
                return
            }

            let posInit = 0
            const ans = Object.keys(props.user.answers).length

            if (ans < 5 || ans == 15 || ans == 30 || ans == 45 || ans == 60 || ans == 75 || ans == 90) {
                posInit = 0
            }

            if (ans == 5 || ans == 20 || ans == 35 || ans == 50 || ans == 65 || ans == 80) {
                posInit = 1
            }

            if (ans == 10 || ans == 25 || ans == 40 || ans == 55 || ans == 70 || ans == 85) {
                posInit = 2
            }   

            state.nextPhase = posInit + 1
            setInitials(0)
        }

        const setInitials = (phase) => {
            const animOrigin = '50% 50%'
            
            const pointShort = state.points.short['phase'+phase]
            const pointLarge = state.points.large['phase'+phase]
            const pointSupreme = state.points.supreme['phase'+phase]

            gsap.set(ship1A.value, {x: pointShort.x,     y: pointShort.y,    rotation: pointShort.rotation, transformOrigin: animOrigin})
            gsap.set(ship1B.value, {x: pointShort.x,     y: pointShort.y,    rotation: pointShort.rotation, transformOrigin: animOrigin})

            gsap.set(ship2A.value, {x: pointLarge.x,     y: pointLarge.y,    rotation: pointLarge.rotation, transformOrigin: animOrigin})
            gsap.set(ship2B.value, {x: pointLarge.x,     y: pointLarge.y,    rotation: pointLarge.rotation, transformOrigin: animOrigin})

            gsap.set(ship3A.value, {x: pointSupreme.x,   y: pointSupreme.y,  rotation: pointSupreme.rotation, transformOrigin: animOrigin})
            gsap.set(ship3B.value, {x: pointSupreme.x,   y: pointSupreme.y,  rotation: pointSupreme.rotation, transformOrigin: animOrigin})
        }

        const moveToPhase = (phase) => {    
            const animDuration = 1.4
            const animEase = 'easeInOut'
            const animOrigin = '50% 50%'

            const pointShort = state.points.short['phase'+phase]
            const pointLarge = state.points.large['phase'+phase]
            const pointSupreme = state.points.supreme['phase'+phase]
            
            gsap.to(ship1A.value, { x: pointShort.x,     y: pointShort.y,    rotation: pointShort.rotation,      duration: animDuration, ease: animEase, transformOrigin: animOrigin, onComplete: () => arrived(phase) })
            gsap.to(ship1B.value, { x: pointShort.x,     y: pointShort.y,    rotation: pointShort.rotation,      duration: animDuration, ease: animEase, transformOrigin: animOrigin, onComplete: () => arrived(phase) })

            gsap.to(ship2A.value, { x: pointLarge.x,     y: pointLarge.y,    rotation: pointLarge.rotation,      duration: animDuration, ease: animEase, transformOrigin: animOrigin, onComplete: () => arrived(phase) })
            gsap.to(ship2B.value, { x: pointLarge.x,     y: pointLarge.y,    rotation: pointLarge.rotation,      duration: animDuration, ease: animEase, transformOrigin: animOrigin, onComplete: () => arrived(phase) })

            gsap.to(ship3A.value, { x: pointSupreme.x,   y: pointSupreme.y,  rotation: pointSupreme.rotation,    duration: animDuration, ease: animEase, transformOrigin: animOrigin, onComplete: () => arrived(phase) })
            gsap.to(ship3B.value, { x: pointSupreme.x,   y: pointSupreme.y,  rotation: pointSupreme.rotation,    duration: animDuration, ease: animEase, transformOrigin: animOrigin, onComplete: () => arrived(phase) })
        }

        const go = () => {
            moveToPhase(state.nextPhase)
            state.nextPhase++
            state.started = true
            emit('go', state.nextPhase)
        }

        const arrived = (phase) => {
            if (phase == 1 || phase == 3) {
                state.flipped = true
            } else {
                state.flipped = false
            }

            state.started = false
            emit('arrived', phase)
        }

        return {
            state,
            go,
            ship1A,
            ship1B,
            ship2A,
            ship2B,
            ship3A,
            ship3B
        }
    },
}
</script>

<style scoped>

.short-rec {
    width: 360px;
}

.large-rec {
    width: 600px;
}

.supreme-rec {
    width: 600px;
}

</style>