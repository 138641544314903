import firebase, {db, auth, fc, st} from './connection'
import store from '../store.js'
import router from '../router.js'
import { sortedIndex } from 'lodash';

export default {
    get(level, numQuestion) {
        let ref = db.collection('questions').doc('nivel'+level).collection(numQuestion).doc('question');
        
        return ref.get()
                    .then(doc => {
                        return {doc: doc};
                    }).catch(error => {
                        return error;
                    });
    },

    presetIt(uid, answers) {
        let ref = db.collection('users').doc(uid);

        return ref.update({
                        answers: answers
                    })
                    .then( () => {
                        let user = store.getters.user;
                        user.answers = answers;
                        store.dispatch('setUser', user);
                        return true;
                    }).catch(error => {
                        return error;
                    });
    },

    saveAnswer(uid, answers, pointsByAnswer, timeUntilNow) {
        let ref = db.collection('users').doc(uid);

        return ref.update({
                        answers: answers,
                        gamePoints: firebase.firestore.FieldValue.increment(pointsByAnswer),
                        timePlayed: firebase.firestore.FieldValue.increment(timeUntilNow)
                    })
                    .then( () => {
                        let user = store.getters.user;
                        user.answers = answers;
                        user.gamePoints += pointsByAnswer;
                        user.timePlayed += timeUntilNow;
                        store.dispatch('setUser', user);
                        return true;
                    }).catch(error => {
                        return error;
                    });
    },

    update(uid, model) {
        let ref = db.collection('questions').doc(uid);

        return ref.update(model)
                    .then( () => {
                        return true;
                    }).catch(error => {
                        return error;
                    });
    }

}