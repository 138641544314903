<template>
<div>
    <div class="text-center text-xs mt-6 space-y-1" :class="route.path=='/play' ? 'text-gray-600' : 'text-gray-500'">
        <p>Derechos reservados.</p>
        <p>Material exclusivo para el profesional de la salud.</p>
        <p>Aviso de publicidad No. 213300C2021850</p>
    </div>

    <div class="text-center text-xs  mt-4 space-x-6 underline" :class="route.path=='/play' ? 'text-gray-600' : 'text-gray-400'">
        <a href="/terminos-y-condiciones" target="_blank">Términos y condiciones</a>
        <a href="/aviso-de-privacidad" target="_blank">Aviso de privacidad</a>
    </div>
</div>
</template>

<script>
import { useRoute } from 'vue-router'

export default {
    setup() {
        const route = useRoute();

        return {
            route
        }
    },
}
</script>