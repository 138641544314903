<template>
    <div class="contenedor px-8">
        <canvas id="game" class="w-full mx-auto rounded-2xl border-4 border-black"></canvas>
    </div>
</template>

<script>
import * as PIXI from 'pixi.js'
import { onMounted, ref, watch, reactive, computed } from 'vue'
import { Howl, Howler } from 'howler'
import { Ease, ease } from 'pixi-ease'

export default {
    props: ['lancha', 'time'],
	setup(props, {emit}) {
        let app;
        let container = new PIXI.Container();

        let me = {
            mats: {
                mar1: PIXI.Texture.from(require('@/assets/game/mar1.jpg')),
                bolaPink: PIXI.Texture.from(require('@/assets/game/bola1.png')),
                bolaCheck: PIXI.Texture.from(require('@/assets/game/bola-check.png')),
                lancha1: PIXI.Texture.from(require('@/assets/game/barcos/barco1.png')),
                lancha2: PIXI.Texture.from(require('@/assets/game/barcos/barco2.png')),
                lancha3: PIXI.Texture.from(require('@/assets/game/barcos/barco3.png')),
                lancha4: PIXI.Texture.from(require('@/assets/game/barcos/barco4.png')),
            }
        };

        const oneTime = ref(true);
        const oneTerminated = ref(true);

        

        const mar1 = new PIXI.Sprite(me.mats.mar1);
        const bolaCheck = new PIXI.Sprite(me.mats.bolaCheck);

        const point = new Howl({ src: require('@/assets/game/sounds/point.mp3') });
        
        const state = reactive({
            checkGone: false
        });

        let lancha;

        if (props.lancha == 1) lancha = new PIXI.Sprite(me.mats.lancha1);
        if (props.lancha == 2) lancha = new PIXI.Sprite(me.mats.lancha2);
        if (props.lancha == 3) lancha = new PIXI.Sprite(me.mats.lancha3);
        if (props.lancha == 4) lancha = new PIXI.Sprite(me.mats.lancha4);

        const factorY = 30;
        const velocity = 10;
        const velCheck = 6;
        const marVelocity = 0.5;
        const checkpointTimeLimit = 30;
        const timeForWaitIfNotCatched = 3;
        let particles = [];

        const ticker = new PIXI.Ticker();

        onMounted(() => {
            setTimeout(() => {
                init();
            }, 2000);
        });

        function init() {
            app = new PIXI.Application({
                width: 1200,
                height: 700,
                antialias: true,   
                // backgroundAlpha: 0,
                backgroundColor: 0xFAFAFA,
                resolution: 1,
                view: document.querySelector("#game")
            });

            app.stage.addChild(container);

            ticker.add(animate);
            ticker.start();

            ease.duration = 1200;
            start();
        }

        function start() {
            mar1.anchor.set(0.5);
            mar1.position.set(0, 240);
            mar1.interactive = true;
            container.addChild(mar1);

            bolaCheck.anchor.set(0.5);
            bolaCheck.scale.set(0.3);
            bolaCheck.position.set(1250, 350);
            bolaCheck.interactive = true;
            container.addChild(bolaCheck);

            lancha.anchor.set(0.5);
            
            if (props.lancha == 1) lancha.scale.set(0.3);
            if (props.lancha == 2) lancha.scale.set(0.2);
            if (props.lancha == 3) lancha.scale.set(0.2);
            if (props.lancha == 4) lancha.scale.set(0.2);

            lancha.position.set(120, 240);
            lancha.interactive = true;
            container.addChild(lancha);

            setParticles();

            document.addEventListener('keydown', onKeyDown);
        }

        function onKeyDown(key) {
            // Up
            if (key.keyCode === 38) {
                if (lancha.position.y >= 250) {
                    lancha.position.y -= factorY;
                }
            }   

            // Down
            if (key.keyCode === 40) {
                if (lancha.position.y <= 630) {
                    lancha.position.y += factorY;
                }
            }
        }

        function setParticles() {
            for (let i=0; i<1000; i++) {
                let bolita = new PIXI.Sprite(me.mats.bolaPink);
                bolita.anchor.set(0.5);
                bolita.scale.set(0.5);
                bolita.position.set(100000 * Math.random() + 600, 2200 * Math.random() + 200);
                bolita.interactive = true;

                particles.push(bolita);
                container.addChild(bolita);
            }
        }

        function collide(a, b) {
            let aBox = a.getBounds();
            let bBox = b.getBounds();

            return  aBox.x + aBox.width > bBox.x &&
                    aBox.x < bBox.x + bBox.width &&
                    aBox.y + aBox.height > bBox.y &&
                    aBox.y < bBox.y + bBox.height;
        }

        watch(oneTime, (current, old) => {
            if (!oneTime.value) {
                setTimeout(() => {
                    oneTime.value = true;
                }, 400)
            }
        });

        // watch(() => time.value.seconds, (current, old) => {
        //     console.log('timer.seconds.value')
        //     if (time.seconds.value > 50) {
        //         ticker.stop();
        //         emit('terminated');
        //         oneTerminated.value = false;
        //     } 
        // });

        function animate() {
            mar1.x -= marVelocity;

            if (!state.checkGone) {
                for (let i = 0; i < particles.length; i++) {
                    const bolita = particles[i];
                    bolita.x -= velocity;
                }
            } else {
                oneTime.value = false;

                for (let i = 0; i < particles.length; i++) {
                    const bolita = particles[i];
                    bolita.x = 0;
                    bolita.visible = false;
                }
            }

            if (oneTime.value) {
                for (let i = 0; i < particles.length; i++) {
                    const bolita = particles[i];

                    if (collide(bolita, lancha)) {
                        container.removeChild(bolita);
                        emit('sumpoints');
                        point.play();
                        oneTime.value = false;
                    }
                }
            }

            if (oneTerminated.value) {
                if (props.time.seconds >= checkpointTimeLimit) {
                    bolaCheck.x -= velCheck;

                    if (collide(bolaCheck, lancha)) {
                        ticker.stop();
                        emit('terminated');
                        oneTerminated.value = false;
                    }

                    if (props.time.seconds > (checkpointTimeLimit + timeForWaitIfNotCatched)) {
                        state.checkGone = true;
                    }

                    if (bolaCheck.x < 0) {
                        bolaCheck.x = 1250;
                    }
                }
            }
        }
    }
}
</script>
