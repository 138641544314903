<template>
    <div class="flex justify-center mt-28">
        <div class="max-w-lg">
            <div>
                <img class="rounded-md border-2 border-primary border-opacity-60" :src="require('@/assets/points/lighthouse.jpg')" alt="">
            </div>

            <div class="text-white text-center mt-8">
                Aún te quedan preguntas pendientes, juega de nuevo para obtener la mayor cantidad de puntos.
            </div>

            <div class="flex justify-center mt-8">
                <button class="py-2 px-8 bg-primary text-white font-medium text-lg rounded-md" @click="play">Jugar de nuevo</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    setup(props, {emit}) {
        function play() {
            emit('play')
        }

        return {
            play
        }
    },
}
</script>