<template>
<div class="flex justify-center mt-8" v-if="state.loaded">
	<div class="bg-gray-100 border-4 border-black rounded-lg px-4 pt-5 pb-1 text-left shadow-xl sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6">
		<div class="flex justify-center">
			<div>
				<div class="flex justify-center"><img :src="require('@/assets/game/question/pleca.png')" class="-mt-10 w-2/5"></div>
				<div class="text-center -mt-10 text-xl font-semibold text-white">Pregunta {{state.actual+1}}</div>
			</div>
		</div>

		<div class="mt-8 sm:mt-12">
			
			<div class="text-lg leading-6 text-center font-medium text-gray-900 md:px-10" v-html="state.question.title"></div>

			<div class="text-center text-sm font-bold text-accent mt-2">
				<span v-if="state.opportunity == 0"><span v-if="state.answeredCorrect!=true">Por</span> 1,000 puntos.</span>
				<span v-if="state.opportunity == 1"><span v-if="state.answeredCorrect!=true">Por</span> 500 puntos.</span>
				<span v-if="state.opportunity == 2">No se aplicaron puntos.</span>
			</div>

			<div class="text-sm text-left text-gray-600 space-y-6 mt-6 mb-4" v-if="!state.sending">
				<div v-for="(item, index) in state.question.answers" :key="item.id">
					<div class="flex items-center">
						<div class="flex-none -mr-6 z-20 mt-1">
							<img :src="require('@/assets/game/question/p1.png')" class="w-12" v-if="index==0">
							<img :src="require('@/assets/game/question/p2.png')" class="w-12" v-if="index==1">
							<img :src="require('@/assets/game/question/p3.png')" class="w-12" v-if="index==2">
							<img :src="require('@/assets/game/question/p4.png')" class="w-12" v-if="index==3">
							<img :src="require('@/assets/game/question/p5.png')" class="w-12" v-if="index==4">
						</div>
						<p class="shadow-md pl-8 pr-3 py-2 rounded-xl flex-1" @click="choosen(index+1)" v-html="item" :class="covered(index+1)"></p>
					</div>
				</div>

				<div class="text-sm text-left text-gray-600 mt-10 px-2" v-if="state.presentFeedback">
					<div v-if="state.question.feedback.length > 0">
						<div class="text-xs font-bold text-accent">COMENTARIOS</div>
						<div class="mt-1" v-html="state.question.feedback"></div>
					</div>

					<div v-if="state.question.reference.length > 0">
						<div class="mt-4 text-xs font-bold text-accent">REFERENCIA</div>
						<div class="mt-1" v-html="state.question.reference"></div>
					</div>
				</div>
			</div>

			<div class="flex justify-center py-20" v-if="state.sending">
				<Loader />
			</div>
		</div>
	</div>
</div>

<div class="flex justify-center" v-if="state.presentFeedback">
	<button class="py-5 px-9 bg-green-500 text-green-50 rounded-md text-base font-medium" @click="next">
		Continuar
	</button>
</div>
</template>

<script>
import { reactive, onMounted, computed } from 'vue'
import { useStore } from 'vuex'
import { CheckIcon } from '@heroicons/vue/outline'
import Questions from '@/firebase/questions'

const activeLevels = 6

export default {
	components: {
		CheckIcon,
	},
	props: ['bolitapoints'],
	emits: ['completed'],
	setup(props, {emit}) {
		const store = useStore();
		const user = computed(() => store.getters.user);

		const state = reactive({
			actual: 0,
			opportunity: 0,
			answeredCorrect: null,
			step: 1,
			blockers: [],
			numResponded: null,
			responded: false,
			presentFeedback: false,
			question: null,
			user: user,
			loaded: false,
			bolitasumed: false,
			level: 1
		});

		onMounted(() => {
			setLevel()
			init();
		});

		function init() {	
			if (state.user.answers == null) {
				getQuestion(state.user.questionOrder[0]);
			} else {
				if (Object.keys(state.user.answers).length < (state.level*15)) {
					let num = getNextToAsk();
					getQuestion(num);
				} else {
					emit('completed')
				}
			}

			if (state.user.answers != null) {
				setLevel()
			}
		}

		function setLevel() {
			if (Object.keys(state.user.answers).length < 15) state.level = 1
			if (Object.keys(state.user.answers).length >= 15 && Object.keys(state.user.answers).length < 30) state.level = 2
			if (Object.keys(state.user.answers).length >= 30 && Object.keys(state.user.answers).length < 45) state.level = 3
			if (Object.keys(state.user.answers).length >= 45 && Object.keys(state.user.answers).length < 60) state.level = 4
		}

		function getNextToAsk() {
			let tempo = null;

			state.user.questionOrder.forEach(dt => {
				let fac = dt
				if (state.level == 2) fac = dt + 15
				if (state.level == 3) fac = dt + 30
				if (state.level == 4) fac = dt + 45

				if (state.user.answers[fac] == undefined && tempo == null) {
					tempo = fac;
				}
			});

			if (tempo == null) {
				emit('checkPointDone');
			} else {
				return tempo;
			}
		}

		function getQuestion(numQuestion) {
			state.loaded = false;

			const lev = String(state.level)
			const num  = String(numQuestion)

			Questions.get(lev, num)
					.then(response => {
						if (response.doc) {
							state.question = response.doc.data();
							state.question.id = numQuestion;
							presetIt(numQuestion);
						} else {
							store.dispatch('setNotification', {type: false, content: 'Hubo un error de conexión, por favor intenta más tarde.'});
						}
					});
		}

		function presetIt(numQuestion) {
			let answers = {};

			if (state.user.answers) {
				answers = state.user.answers;
			}
			 
			answers[numQuestion] = {
				valued: false
			};

			Questions.presetIt(state.user.uid, answers)
					.then(response => {
						if (response === true) {
							state.loaded = true;
						} else {
							store.dispatch('setNotification', {type: false, content: 'Hubo un error de conexión, por favor intenta más tarde.'});
						}
					});
		}

		function choosen(num) {
			if (state.opportunity < 2 && state.answeredCorrect != true && state.numResponded != num) {
				state.responded = true;
				state.numResponded = num;

				if (num == state.question.correct) {
					saveAnswer(state.question.id);
					setBlock(num);
				} else {
					state.answeredCorrect = false;
					state.opportunity++;
				}
			}

			if (state.opportunity == 2) {
				state.presentFeedback = true;
			}
		}

		function saveAnswer(numQuestion) {
			state.sending = true;

			let answers = state.user.answers;
			 
			answers[numQuestion].valued = true;

			if (state.opportunity == 0) {
				answers[numQuestion].points = 1000;
			}
			if (state.opportunity == 1) {
				answers[numQuestion].points = 500;
			}

			let finalPoints = answers[numQuestion].points;

			if (state.bolitasumed === false) {
				finalPoints += props.bolitapoints;
				state.bolitasumed = true;
			}

			let initTimer = store.getters.timer;
			let actualTimer = Date.now();
			let finalTimer = actualTimer - initTimer;

			Questions.saveAnswer(state.user.uid, answers, finalPoints, finalTimer)
					.then(response => {
						if (response === true) {
							state.answeredCorrect = true;
							state.presentFeedback = true;
							state.sending = false;
						} else {
							store.dispatch('setNotification', {type: false, content: 'Hubo un error de conexión, por favor intenta más tarde.'});
						}
					});
		}

		function setBlock(num) {
			state.question.answers.forEach( (dt, index) => {
				if (index != (num-1)) {
					state.blockers.push(index)
				}
			})
		}

		function next() {
			state.actual++;
			if (state.actual < 4) {
				clear();
				init();
			} else {
				emit('checkPointDone');
			}
		}

		function clear() {
			state.answeredCorrect = false;
			state.opportunity = 0;
			state.blockers = [];
			state.responded = false;
			state.numResponded = null;
			state.presentFeedback = false;
			state.loaded = false;
		}

		function overed(num) {
			if (state.responded) {
				// CORRECTA
				if (state.answeredCorrect === true) {
					if (state.numResponded == num) {
						return 'bg-green-500 text-white cursor-not-allowed';	
					} else {
						return 'opacity-40 cursor-not-allowed';
					}
				}
				
				// INCORRECTA
				if (state.answeredCorrect === false) {
					if (state.numResponded == num) {
						return 'bg-red-500 text-white cursor-not-allowed';
					} 
					
					if (state.opportunity == 2) {
						return 'bg-white text-gray-900 opacity-40 cursor-not-allowed';
					}
				}
			}
			
			return 'bg-white hover:bg-blue-500 hover:text-blue-50 cursor-pointer'
		}

		return {
			state,
			close,
			choosen,
			next,
			overed
		}
	},
}
</script>