// const data = {
//     short: {
//         phase0: {
//             x: 50,
//             y: 88,
//             rotation: 0
//         },
//         phase1: {
//             x: 104,
//             y: 91,
//             rotation: -6
//         },
//         phase2: {
//             x: 172,
//             y: 106,
//             rotation: -2
//         },
//         phase3: {
//             x: 236,
//             y: 91,
//             rotation: -6
//         },
//         phase4: {
//             x: 276,
//             y: 92,
//             rotation: -10
//         }
//     },
//     large: {
//         phase0: {
//             x: 110,
//             y: 176,
//             rotation: 0
//         },
//         phase1: {
//             x: 210,
//             y: 182,
//             rotation: -6
//         },
//         phase2: {
//             x: 342,
//             y: 210,
//             rotation: -4
//         },
//         phase3: {
//             x: 464,
//             y: 180,
//             rotation: -10
//         },
//         phase4: {
//             x: 540,
//             y: 176,
//             rotation: 0
//         }
//     },
//     supreme: {
//         phase0: {
//             x: 140,
//             y: 228
//         },
//         phase1: {
//             x: 270,
//             y: 236,
//             rotation: -10
//         },
//         phase2: {
//             x: 438,
//             y: 270,
//             rotation: -2
//         },
//         phase3: {
//             x: 598,
//             y: 236,
//             rotation: -10
//         },
//         phase4: {
//             x: 696,
//             y: 234,
//             rotation: -10
//         }
//     }
// }

const data = {
    short: {
        phase0: {
            x: 130,
            y: 84,
            rotation: 4
        },
        phase1: {
            x: 238,
            y: 112,
            rotation: -4
        },
        phase2: {
            x: 92,
            y: 170,
            rotation: 4
        },
        phase3: {
            x: 238,
            y: 240,
            rotation: -4
        },
        phase4: {
            x: 150,
            y: 280,
            rotation: -10
        }
    },
    large: {
        phase0: {
            x: 210,
            y: 132,
            rotation: 6
        },
        phase1: {
            x: 390,
            y: 184,
            rotation: -6
        },
        phase2: {
            x: 168,
            y: 284,
            rotation: 6
        },
        phase3: {
            x: 394,
            y:  400,
            rotation: -6
        },
        phase4: {
            x: 250,
            y: 462,
            rotation: 0
        }
    },
    supreme: {
        phase0: {
            x: 210,
            y: 132,
            rotation: 6
        },
        phase1: {
            x: 390,
            y: 184,
            rotation: -6
        },
        phase2: {
            x: 168,
            y: 284,
            rotation: 6
        },
        phase3: {
            x: 394,
            y:  400,
            rotation: -6
        },
        phase4: {
            x: 250,
            y: 462,
            rotation: 0
        }
    }
}

export default { data }